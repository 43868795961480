import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'
import { useSnackbar } from 'notistack'

import { ISubmitData } from '../../types'

const updateAllocation = async (updateData: ISubmitData) => {
  const isOverhours = updateData.isOverhours
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ALLOCATIONS_UPDATE,
    {
      FTE: updateData.fte,
      userProjectId: updateData.userProjectId,
      date: updateData.date,
      allWorkingHours: updateData.allWorkingHours,
      isOverhours,
    },
    {
      id: updateData.id,
    }
  )
  return data
}

export const useEditAllocation = () => {
  const queryClient = useQueryClient()

  const { handleCloseModal } = useNotifications()
  const snack = useSnackbar()
  const { t } = useTranslation('handleMessage')
  const { mutate: updateUserAllocation } = useMutation(updateAllocation, {
    onSuccess: () => {
      handleCloseModal()
      snack.enqueueSnackbar(`${t('allocation')} ${t('edit')}`, {
        variant: 'success',
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  return { updateUserAllocation }
}
