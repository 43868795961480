import { I18nextProvider } from 'react-i18next'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ReactQueryDevtools } from 'react-query/devtools'
import { NotificationsProvider } from 'components/common'
import QueryProvider from 'react-query/queryProvider'
import { HelmetProvider } from 'react-helmet-async'
import { SnackbarProvider } from 'notistack'
import enLocale from 'date-fns/locale/en-GB'

import App from './App'
import { i18next } from './services'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'

import './index.css'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <NotificationsProvider>
        <QueryProvider>
          <I18nextProvider i18n={i18next}>
            <HelmetProvider>
              <Router>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enLocale}
                >
                  <ThemeProvider theme={theme}>
                    <App />
                  </ThemeProvider>
                </LocalizationProvider>
              </Router>
            </HelmetProvider>
          </I18nextProvider>
          <ReactQueryDevtools />
        </QueryProvider>
      </NotificationsProvider>
    </SnackbarProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
