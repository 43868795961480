import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'
import { useSnackbar } from 'notistack'

import { ISubmitData } from '../../types'

const createNewAllocation = async (addData: ISubmitData) => {
  const isOverhours = addData.isOverhours
  const { data }: AxiosResponse = await apiRequest(APIRoutes.ALLOCATIONS_ADD, {
    FTE: Number(addData.fte),
    userProjectId: addData.userProjectId,
    date: addData.date,
    hours: addData.allWorkingHours,
    isOverhours,
  })

  return data
}

export const useAddAllocation = () => {
  const queryClient = useQueryClient()

  const { handleCloseModal } = useNotifications()
  const snack = useSnackbar()
  const { t } = useTranslation('handleMessage')
  const { mutate: addNewAllocation } = useMutation(createNewAllocation, {
    onSuccess: () => {
      handleCloseModal()
      snack.enqueueSnackbar(`${t('allocation')} ${t('add')}`, {
        variant: 'success',
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.userOne])
      void queryClient.invalidateQueries([queryKeys.user])
    },
  })

  return { addNewAllocation }
}
